import React, { useState,useEffect } from 'react';
import { Box, List, ListItem, Icon, Flex, Image, Button, useBreakpointValue,
    Text, VStack
 } from '@chakra-ui/react';
import { MdDashboard, MdSettings, MdPerson, MdLogout, MdAccountCircle, MdPhoneIphone, MdLibraryBooks,
    MdPayment, MdAdminPanelSettings, MdVpnKey
 } from 'react-icons/md';
import { RiFunctionAddLine } from "react-icons/ri";
import { Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';

// Navigation component
const Navigation = () => {
  const navigate = useNavigate();
  const iconSize = useBreakpointValue({ base: '2rem', md: '1.5rem' });
  const [ isAdmin, setIsAdmin ] = useState(false);

  const navItems = [
    { label: 'Dashboard', icon: MdDashboard, route: '/dashboard' },
    { label: 'Assistant', icon: MdPerson, route: '/assistant' },
    { label: 'Phone Numbers', icon: MdPhoneIphone, route: '/phone-numbers' },
    { label: 'Knowledgebase', icon: MdLibraryBooks, route: '/knowledgebase' },
    { label: 'Tool', icon: RiFunctionAddLine, route: '/tool' },
    { label: 'Settings', icon: MdSettings, route: '/settings' },
    { label: 'API Keys', icon: MdVpnKey, route: '/api-keys' },
    { label: 'Profile', icon: MdAccountCircle, route: '/profile' },
    { label: 'Billing', icon: MdPayment, route: '/billing' },
    { label: 'Admin', icon: MdAdminPanelSettings, route: '/admin/create-user', is_admin:true },
  ];
  

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Make API call to check if user is authenticated
        const response = await axios.get('/api/checkIsAdmin');
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAdmin(false);  // Assume not authenticated if there's an error
      } finally {
      }
    };

    checkAuthentication();  // Check authentication when component mounts
  }, []);

  // Logout function
  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  

  return (
    <Flex direction="column"  alignItems="center" justifyContent="space-between" bg="gray.800" color="white" p={4} height="100vh">
      <Box>
        <Flex alignItems="center" mb={6} justifyContent="center">
          {/* Logo in the top left corner */}
          <Image
            src="/logo.png"
            alt="Logo"
            width="200px"
            display={{ base: 'none', md: 'block' }} // Hide logo on small screens
          />
          <Image
            src="/logo-small.png"
            alt="Logo"
            width="30px"
            display={{ base: 'block', md: 'none' }} // Hide logo on small screens
          />
        </Flex>

        <VStack
            as="nav"
            spacing={4}
            align="stretch"
            // color="brand.500"
            height="100vh"
            // width="200px"
        >
            {navItems.map((item) => {
                // Check if the item is admin-only and if the user has admin rights
                if (item.is_admin && !isAdmin) return null;

                return (
                <NavLink
                    key={item.route}
                    to={item.route}
                    style={({ isActive }) => ({
                        backgroundColor: isActive ? '#4D5768' : 'transparent',
                        color: isActive ? 'teal.300' : 'white',
                    })}
                >
                    <Flex align="center" p={2} borderRadius="md" _hover={{ bg: 'gray.600' }}>
                        <Icon as={item.icon} boxSize={{ base: '2rem', md: '1.5rem' }} mr={2} />
                        <Text display={{ base: 'none', md: 'block' }} fontSize="lg">{item.label}</Text>
                    </Flex>
                </NavLink>
                );
            })}

            {/* Logout Button */}
            <Button
                onClick={handleLogout}
                leftIcon={<MdLogout size={iconSize} />} // Use dynamic icon size
                width="full"
                colorScheme="red"
                >
                <Box display={{ base: 'none', md: 'block' }}>Logout</Box> {/* Hide text on small screens */}
            </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Navigation;
