import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  VStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  useToast,
  IconButton,
  Tooltip,
  Text, Flex
} from '@chakra-ui/react';
import { MdVpnKey, MdDelete, MdEdit } from 'react-icons/md';
import Navigation from '../components/Navigation';
import axios from 'axios';

const ApiKeyManagement = () => {
  const [apiKeys, setApiKeys] = useState([]); // Stores all API keys
  const [keyName, setKeyName] = useState(''); // Input for naming the key
  const [newKey, setNewKey] = useState(null); // Temporarily holds the generated key
  const [editingKey, setEditingKey] = useState(null); // Key being renamed
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal state management
  const { isOpen: isRenameOpen, onOpen: onRenameOpen, onClose: onRenameClose } = useDisclosure(); // Rename modal state
  const toast = useToast();

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await axios.get('/api/api-keys'); // Replace with your backend endpoint
        // console.log(response.data);
        setApiKeys(response.data); // Assume the backend returns a list of API keys
      } catch (error) {
        console.error('Error fetching API keys:', error);
        toast({
          title: 'Error',
          description: 'Unable to fetch API keys.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    fetchApiKeys();
  }, []);

  const resetModalState = () => {
    setKeyName(''); // Reset name input
    setNewKey(null); // Reset generated key
  };

  const generateApiKey = async () => {
    try {
      // Replace this URL with your actual backend endpoint for generating API keys
      const response = await axios.post('/api/generate-key', { name: keyName });
      const { id, maskedKey, key, createdAt, lastUsed } = response.data.key;
  
      // Update state with the new key
      setNewKey({ id, key, createdAt, lastUsed });
      setApiKeys((prevKeys) => [...prevKeys, { name: keyName, id, maskedKey, createdAt, lastUsed }]);
  
      toast({
        title: 'API Key Generated',
        description: 'Your new API key has been successfully created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating API key:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while generating the API key.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const revokeApiKey = async (id) => {
    try {
        await axios.delete(`/api/api-keys/${id}`); // Replace with your backend endpoint
        setApiKeys((prevKeys) => prevKeys.filter((k) => k._id !== id));
        toast({
          title: 'API Key Deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting API key:', error);
        toast({
          title: 'Error',
          description: 'Unable to delete API key.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
  };

  const handleRename = async (id) => {
    if (!keyName) return;
    try {
        const response = await axios.put(`/api/api-keys/${id}`, { name: keyName }); // Replace with your backend endpoint
        const updatedKey = response.data.apiKey;
        setApiKeys((prevKeys) =>
            prevKeys.map((k) => (k._id === id ? updatedKey : k))
        );
        toast({
            title: 'API Key Renamed',
            description: 'The key has been successfully renamed.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
        onRenameClose();
    } catch (error) {
        console.error('Error renaming API key:', error);
        toast({
            title: 'Error',
            description: 'Unable to rename API key.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
  };

  return (
    <Flex height="100vh" justifyContent="space-between">
      <Navigation />

      <Box p={8} flex="1" overflowX="auto">
      <Heading size="lg" mb={6}>
        Manage API Keys
      </Heading>

      {/* API Key Table */}
      <Table variant="simple" bg="white" borderWidth="1px" borderRadius="md" mb={6}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Masked Key</Th>
            <Th>Created</Th>
            <Th>Last Used</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {apiKeys.map((key) => (
            <Tr key={key._id}>
              <Td>{key.name}</Td>
              <Td>{key.maskedKey}</Td>
              <Td>{new Date(key.createdAt).toLocaleString()}</Td>
              <Td>{key.lastUsedAt ? new Date(key.lastUsedAt).toLocaleString() : 'Never'}</Td>
              <Td>
                <HStack spacing={2}>
                  <Tooltip label="Rename Key" aria-label="Rename API Key">
                    <IconButton
                      icon={<MdEdit />}
                      size="sm"
                      onClick={() => {
                        setEditingKey(key);
                        setKeyName(key.name);
                        onRenameOpen();
                      }}
                      aria-label="Rename API Key"
                    />
                  </Tooltip>
                  <Tooltip label="Delete Key" aria-label="Delete API Key">
                    <IconButton
                      icon={<MdDelete />}
                      size="sm"
                      colorScheme="red"
                      onClick={() => revokeApiKey(key._id)}
                      aria-label="Delete API Key"
                    />
                  </Tooltip>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Generate Key Button */}
      <Button
        leftIcon={<MdVpnKey />}
        colorScheme="blue"
        onClick={() => {
          resetModalState();
          onOpen();
        }}
      >
        Generate API Key
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Generate API Key</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {newKey ? (
        <>
          <Text>Here is your API key. Copy it now; it won’t be shown again.</Text>
          <HStack mt={3}>
            <Input value={newKey.key} isReadOnly />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(newKey.key);
                toast({
                  title: 'API Key Copied',
                  description: 'The API key has been copied to your clipboard.',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                });
              }}
              colorScheme="blue"
            >
              Copy
            </Button>
          </HStack>
        </>
      ) : (
        <VStack spacing={4} align="stretch">
          <Input
            placeholder="Enter key name"
            value={keyName}
            onChange={(e) => setKeyName(e.target.value)}
          />
        </VStack>
      )}
    </ModalBody>
    <ModalFooter>
      {newKey ? (
        <Button colorScheme="blue" onClick={onClose}>
          Done
        </Button>
      ) : (
        <Button
          colorScheme="blue"
          onClick={generateApiKey}
          isDisabled={!keyName.trim()}
        >
          Generate Key
        </Button>
      )}
    </ModalFooter>
  </ModalContent>
</Modal>

      {/* Rename Key Modal */}
      <Modal isOpen={isRenameOpen} onClose={onRenameClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename API Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter new key name"
              value={keyName}
              onChange={(e) => setKeyName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => handleRename(editingKey._id)}
              isDisabled={!keyName.trim()}
            >
              Rename
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
    </Flex>
  );
};

export default ApiKeyManagement;