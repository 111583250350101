import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import axios from 'axios';

const PhoneNumberManager = () => {
  const [selectedCountry, setSelectedCountry] = useState({ code: '+65', name: 'Singapore', flag: '🇸🇬' });
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]); // list of phone numbers [{countryCode, phoneNumber}]
  const [loading, setLoading] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null); // For editing
  const toast = useToast();

  const countryCodes = [
    { code: '+65', name: 'Singapore', flag: '🇸🇬' },
    { code: '+86', name: 'China', flag: '🇨🇳' },
    { code: '+91', name: 'India', flag: '🇮🇳' },
    { code: '+62', name: 'Indonesia', flag: '🇮🇩' },
    { code: '+81', name: 'Japan', flag: '🇯🇵' },
    { code: '+82', name: 'South Korea', flag: '🇰🇷' },
    { code: '+63', name: 'Philippines', flag: '🇵🇭' },
    { code: '+66', name: 'Thailand', flag: '🇹🇭' },
    { code: '+84', name: 'Vietnam', flag: '🇻🇳' },
    { code: '+95', name: 'Myanmar', flag: '🇲🇲' },
    { code: '+880', name: 'Bangladesh', flag: '🇧🇩' },
    { code: '+977', name: 'Nepal', flag: '🇳🇵' },
    { code: '+94', name: 'Sri Lanka', flag: '🇱🇰' },
    { code: '+92', name: 'Pakistan', flag: '🇵🇰' },
    { code: '+960', name: 'Maldives', flag: '🇲🇻' },
    { code: '+670', name: 'Timor-Leste', flag: '🇹🇱' },
    { code: '+855', name: 'Cambodia', flag: '🇰🇭' },
    { code: '+856', name: 'Laos', flag: '🇱🇦' },
    { code: '+993', name: 'Turkmenistan', flag: '🇹🇲' },
    { code: '+998', name: 'Uzbekistan', flag: '🇺🇿' },
    { code: '+992', name: 'Tajikistan', flag: '🇹🇯' },
    { code: '+996', name: 'Kyrgyzstan', flag: '🇰🇬' },
    { code: '+995', name: 'Georgia', flag: '🇬🇪' },
    { code: '+374', name: 'Armenia', flag: '🇦🇲' },
    { code: '+90', name: 'Turkey', flag: '🇹🇷' },
    { code: '+971', name: 'United Arab Emirates', flag: '🇦🇪' },
    { code: '+973', name: 'Bahrain', flag: '🇧🇭' },
    { code: '+974', name: 'Qatar', flag: '🇶🇦' },
    { code: '+968', name: 'Oman', flag: '🇴🇲' },
    { code: '+966', name: 'Saudi Arabia', flag: '🇸🇦' },
    { code: '+964', name: 'Iraq', flag: '🇮🇶' },
    { code: '+98', name: 'Iran', flag: '🇮🇷' },
    { code: '+972', name: 'Israel', flag: '🇮🇱' },
    { code: '+962', name: 'Jordan', flag: '🇯🇴' },
    { code: '+961', name: 'Lebanon', flag: '🇱🇧' },
    { code: '+963', name: 'Syria', flag: '🇸🇾' },
    { code: '+970', name: 'Palestine', flag: '🇵🇸' },
    { code: '+93', name: 'Afghanistan', flag: '🇦🇫' },
    { code: '+994', name: 'Azerbaijan', flag: '🇦🇿' },
  ];

  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/settings/phone-numbers');
        setPhoneNumbers(response.data.phoneNumbers);
      } catch (error) {
        console.error('Error fetching phone numbers:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPhoneNumbers();
  }, []);

  const handleAddPhoneNumber = async () => {
    setLoading(true);
    try {
      const newPhone = {
        countryCode: selectedCountry.code,
        phoneNumber: newPhoneNumber
      }
      const response = await axios.post('/api/settings/phone-numbers', {
        ...newPhone,
      });
      setPhoneNumbers(response.data.phoneNumbers); // Update state with new phone numbers
      toast({
        title: 'Phone number added successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding phone number:', error);
      toast({
        title: 'Failed to add phone number.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditPhoneNumber = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`/api/settings/phone-numbers/${selectedPhone._id}`, {
        ...selectedPhone,
      });
      setPhoneNumbers(response.data.phoneNumbers); // Update state
      toast({
        title: 'Phone number updated successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating phone number:', error);
      toast({
        title: 'Failed to update phone number.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePhoneNumber = async (phoneId) => {
    setLoading(true);
    try {
      const response = await axios.delete(`/api/settings/phone-numbers/${phoneId}`);
      setPhoneNumbers(response.data.phoneNumbers); // Update state
      toast({
        title: 'Phone number deleted successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting phone number:', error);
      toast({
        title: 'Failed to delete phone number.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (selectedPhone) {
      handleEditPhoneNumber(selectedPhone);
    } else {
      handleAddPhoneNumber(newPhoneNumber);
    }
    setSelectedPhone(null); // Clear selected phone after operation
  };

  return (
    <Box p={6}>
      <FormControl mb={4}>
        <FormLabel>New Phone Number</FormLabel>
        <HStack>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  size="lg" width="250px">
              <HStack>
                <Text fontSize="lg">{selectedCountry.flag}</Text>
                <Text>{selectedCountry.code}</Text>
              </HStack>
            </MenuButton>
            <MenuList maxH="300px" overflowY="auto">
              {countryCodes.map((country) => (
                <MenuItem
                  key={country.code}
                  onClick={() => setSelectedCountry(country)}
                  display="flex"
                  alignItems="center"
                >
                  <Text fontSize="lg" mr={2}>
                    {country.flag}
                  </Text>
                  <Text>{`${country.code} (${country.name})`}</Text>
                </MenuItem>
              ))}
              {/* <MenuDivider />
              <MenuItem disabled>More countries coming soon...</MenuItem> */}
            </MenuList>
          </Menu>
          <Input
            placeholder="Enter phone number"
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
          />
          <Button colorScheme="teal" onClick={handleAddPhoneNumber}>
            Add
          </Button>
        </HStack>
      </FormControl>

      <VStack spacing={3} align="stretch">
        {phoneNumbers.map((number, index) => (
            <HStack
            key={number._id || index}
            justifyContent="space-between"
            bg="gray.100"
            p={3}
            borderRadius="md"
            >
            <Box>
            <Text fontWeight="bold">{number.phoneNumber}</Text>
            <Text fontSize="sm" color="gray.600">
                Country Code: {number.countryCode}{" "}
                {(() => {
                const country = countryCodes.find((c) => c.code === number.countryCode);
                return country ? country.flag : "🏳️";
                })()}
            </Text>
            </Box>

            <HStack>
 
                <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleDeletePhoneNumber(number._id)}
                >
                Delete
                </Button>
            </HStack>
            </HStack>
        ))}
        </VStack>
    </Box>
  );
};

export default PhoneNumberManager;