// RightPanel.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure, useToast, AlertDialog,
  AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Modal, Button, Grid, Input, Select, 
  FormLabel, Heading

} from '@chakra-ui/react';
import axios from 'axios';
import { mulaw } from 'alawmulaw';
import { RightPanelHeader, ChatGPTPromptsTab, ConfigurationTab } from '../components/rightpanel/index';

const RightPanel = ({ selectedAssistant, onSave, onNewAssistant, onDelete, knowledgeBaseFiles, tools }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [formState, setFormState] = useState(selectedAssistant || {});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fromPhoneNumber, setFromPhoneNumber] = useState('');
  const [yourPhones, setYourPhones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assistantPhones, setAssistantPhones] = useState([]);
  const [voiceLoading, setVoiceLoading] = useState(false);
  const [voices, setVoices] = useState([]);
  const toast = useToast();
  const cancelRef = useRef();
  const voiceRef = useRef(null); 
  const modelRef = useRef(null); 
  const audioBufferQueue = useRef([]); // Use ref to persist across renders
  const audioContext = useRef(new (window.AudioContext || window.webkitAudioContext)({
    sampleRate: 8000,
  }));
  const [isPlaying, setIsPlaying] = useState(false);
  const [socket, setSocket] = useState(null); // Store WebSocket instance
  const [formErrors, setFormErrors] = useState("");

  // Update form state when selectedAssistant changes
  useEffect(() => {
    const modelDefaultValue = modelRef.current?.options[0]?.value || '';
    const voiceDefaultValue = voiceRef.current?.options[0]?.value || '';

    fetchVoices();

    setFormState({
      ...selectedAssistant,
      model: selectedAssistant?.model || modelDefaultValue,
      cartesiaVoiceId: selectedAssistant?.cartesiaVoiceId || voiceDefaultValue,
      hangupTimeout: selectedAssistant?.hangupTimeout || 300,
      hangupTrigger: selectedAssistant?.hangupTrigger || 'good bye',
      voiceSpeed: selectedAssistant?.voiceSpeed || 0,
      knowledgebases: selectedAssistant?.knowledgebases || [],
      tools: selectedAssistant?.tools || [],
    });
  }, [selectedAssistant]);

    const handleUrlChange = (e) => {
        const value = e.target.value;
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    
        if (!urlPattern.test(value) && value !== '') {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            url: 'Please enter a valid URL',
        }));
        } else {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            url: '',
        }));
        }
    
        setFormState((prevState) => ({
            ...prevState,
            webhookUrl: value,
        }));
    };

  // Fetch available voices
  const fetchVoices = async (force = false) => {
    try {
      const cachedVoices = localStorage.getItem('cartesia_voices');
      if (cachedVoices && !force) {
        setVoices(JSON.parse(cachedVoices));
      } else {
        const response = await axios.get('/api/voices');
        setVoices(response.data);
        localStorage.setItem('cartesia_voices', JSON.stringify(response.data));
      }
    } catch (err) {
      console.error('Error fetching voices:', err);
    }
  };

  useEffect(() => {
    fetchVoices();
  }, []);

  // Modal actions
  const handleOpenModal = async () => {
    try {
      const response = await axios.get(`/api/assistantphones/${selectedAssistant._id}`);
      setAssistantPhones(response.data);
      if (response.data.length > 0) {
        setFromPhoneNumber(response.data[0]);
      } else {
        toast({
            title: 'No outbound phone number found. Please double check under Phone Numbers page and assign a number with this assistant as outbound',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });   
      }

        const response2 = await axios.get('/api/settings/phone-numbers');
        setYourPhones(response2.data.phoneNumbers);
        if (response2.data.phoneNumbers.length > 0) {
            setPhoneNumber(response2.data.phoneNumbers[0].countryCode + response2.data.phoneNumbers[0].phoneNumber);
        } else {
            toast({
                title: 'Your phone numbers not found. Please double check under Settings, Your Phone Number tab and add in your number',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });   
          }

      onOpen();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCall = async () => {
    setLoading(true);
    try {
      await axios.post('/api/outboundcall', { from: fromPhoneNumber, to: phoneNumber });
      onClose();
      setIsDialogOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSave = () => {
    onSave(formState);
  };

  const handleDeleteClick = () => {
    setIsDialogOpen(false);
    onDelete(selectedAssistant);
  };

  // Form input handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSliderChange = (val, name) => {
    setFormState((prevState) => ({ ...prevState, [name]: val }));
  };

  const handleFileSelect = (fileId) => {
    const isSelected = formState.knowledgebases?.some((kb) => kb._id === fileId);
  
    const updatedFiles = isSelected
      ? formState.knowledgebases.filter((kb) => kb._id !== fileId)  // Deselect file
      : [...(formState.knowledgebases || []), knowledgeBaseFiles.find((file) => file._id === fileId)];  // Select file
  
    // Update the formState with the new selected files
    setFormState((prevState) => ({
      ...prevState,
      knowledgebases: updatedFiles,
    }));
  
  };

  const handleToolSelect = (toolId) => {
    const isSelected = formState.tools?.some((t) => t._id === toolId);
  
    const updatedTools = isSelected
      ? formState.tools.filter((t) => t._id !== toolId)  // Deselect file
      : [...(formState.tools || []), tools.find((t) => t._id === toolId)];  // Select file
  
    // Update the formState with the new selected files
    setFormState((prevState) => ({
      ...prevState,
      tools: updatedTools,
    }));
  
  };

  const handleVoiceRefresh = async () => {
    setVoiceLoading(true);
    await fetchVoices(true);
    setVoiceLoading(false);
  };


  const connectAssistant = async () => {
    try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('Microphone access granted.');
    
        if (audioContext.current.state === 'suspended') {
          await audioContext.current.resume();
          console.log('AudioContext resumed.');
        }

        const newSocket = new WebSocket(`wss://aeb1-103-252-200-95.ngrok-free.app/connection`);
        setSocket(newSocket);

        newSocket.onmessage = (message) => {
            try {
                const data = JSON.parse(message.data);
                console.log('WebSocket message received', data);
                const event = data.event;
                if(event === 'media') {
                    // const muLawChunk = new Uint8Array(data.media.payload);
                    if(data.media.payload) {
                        const binaryString = atob(data.media.payload); // Decode Base64 to binary string
                        const len = binaryString.length;
                        const bytes = new Uint8Array(len);
                        for (let i = 0; i < len; i++) {
                            bytes[i] = binaryString.charCodeAt(i);
                        }
                        const uint8arr = bytes;
                        audioBufferQueue.current.push(...uint8arr);
                        playAudioChunks();
                        // console.log(audioBufferQueue.current.length);
                        // if(audioBufferQueue.current.length > 100) {
                        //     if (!isPlaying) playAudioChunks();
                        // }
                        

                        // audioBufferQueue.current.push(float32Samples);
                        // if (!isPlaying) playAudioChunks();
                    }
                    
                }else if(event === 'mark') {
                    if (!isPlaying) playAudioChunks();
                }else if(event === 'clear') {
                    // stop audio playing
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        newSocket.onclose = () => {
          console.log('WebSocket closed');
        };
      
        newSocket.onopen = async () => {
          try {
            console.log('WebSocket connected');
            const response = await axios.post('/api/webcall');
            const { token } = response.data || {};
            console.log(token);
            newSocket.send(JSON.stringify({
              event: 'start',
              start: {
                  customParameters: {
                      jwt:token
                  },
                  streamSid: "123",
                  callSid: "123"
              }
              
            }));
          } catch (error) {
            console.error(error);
          }
        };
      
        try {
            await audioContext.current.audioWorklet.addModule('./processor.js');

        }catch(error){
            console.log(error);
        }
        const source = audioContext.current.createMediaStreamSource(mediaStream);
        const micProcessor = new AudioWorkletNode(audioContext.current, 'mic-processor');
    
        micProcessor.port.onmessage = (event) => {
          const int16Data = float32ToInt16(event.data);
          const muLawData = mulaw.encode(int16Data); // Encode to μ-law
          const base64Payload = btoa(String.fromCharCode(...muLawData)); // Base64-encode

          if (newSocket.readyState === WebSocket.OPEN) {
            newSocket.send(JSON.stringify({
                event:"media",
                media: {
                    payload:base64Payload
                }
            })); // Send to backend
          }
        };
    
        source.connect(micProcessor);
      } catch (error) {
        console.error('Microphone access denied.', error);
      }
    
  };


 // Convert Float32 to Int16 for sending audio
 const float32ToInt16 = (float32Array) => {
    const int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      int16Array[i] = Math.min(1, float32Array[i]) * 32767;
    }
    return int16Array;
  };

  // Playback function for queued audio chunks
  const playAudioChunks = async () => {
    if (audioBufferQueue.current.length === 0) {
        console.log('empty audioBufferQueue');
      setIsPlaying(false);
      return;
    }

    setIsPlaying(true);
    console.log(audioBufferQueue);

    const pcmSamples = mulaw.decode(Uint8Array.from(audioBufferQueue.current));
    const float32Samples = Float32Array.from(pcmSamples, (x) => x / 32768);
    console.log(float32Samples);

    // const pcmChunk = audioBufferQueue.current.shift();
    const audioBuffer = await pcmToAudioBuffer(float32Samples);

    const source = audioContext.current.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.current.destination);
    source.start();
    source.onended = () => setIsPlaying(false);
    audioBufferQueue.current = [];
  };

  // Convert PCM to AudioBuffer
  const pcmToAudioBuffer = async (pcmChunk) => {
    console.log(`Received audio chunk of length: ${pcmChunk.length}`);
    const buffer = audioContext.current.createBuffer(1, pcmChunk.length, 8000);
    buffer.copyToChannel(pcmChunk, 0);
    return buffer;
  };

  return (
    <Box width="85%" bg="gray.50" p={6}>
      <RightPanelHeader
        selectedAssistant={selectedAssistant}
        handleOpen={handleOpenModal}
        handleSave={handleSave}
        onNewAssistant={onNewAssistant}
      />

      {selectedAssistant && (
        <Tabs index={activeTab} onChange={(index) => setActiveTab(index)}>
          <TabList>
            <Tab>ChatGPT Prompts</Tab>
            <Tab>Configuration</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="sm" mb={4}>ChatGPT Model Settings</Heading>
              <ChatGPTPromptsTab
                formState={formState}
                handleInputChange={handleInputChange}
                handleFileSelect={handleFileSelect}
                handleToolSelect={handleToolSelect}
                handleSliderChange={handleSliderChange}
                knowledgeBaseFiles={knowledgeBaseFiles}
                tools={tools}
                modelRef={modelRef}
                handleUrlChange={handleUrlChange}
                formErrors={formErrors}
              />
            </TabPanel>

            <TabPanel>
              <ConfigurationTab
                formState={formState}
                handleInputChange={handleInputChange}
                handleSliderChange={handleSliderChange}
                handleVoiceRefresh={handleVoiceRefresh}
                voiceLoading={voiceLoading}
                setIsDialogOpen={setIsDialogOpen}
                handleCheckboxChange={handleCheckboxChange}
                voices={voices}
                voiceRef={voiceRef}
                handleUrlChange={handleUrlChange}
                formErrors={formErrors}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      <AlertDialog isOpen={isDialogOpen} leastDestructiveRef={cancelRef} onClose={() => setIsDialogOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Assistant
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure? You can't undo this action.</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button colorScheme="red" onClick={handleDeleteClick} ml={3}>Delete</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Phone Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap="10px">
              <FormLabel>Call From</FormLabel>
              <Select value={fromPhoneNumber} onChange={(e) => setFromPhoneNumber(e.target.value)}>
                {assistantPhones.map((phone) => (
                  <option key={phone} value={phone}>{phone}</option>
                ))}
              </Select>
              <FormLabel>Call To</FormLabel>
              <Select value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}>
                {yourPhones.map((phone) => (
                  <option key={phone.countryCode + phone.phoneNumber} value={phone.countryCode + phone.phoneNumber}>{phone.countryCode + phone.phoneNumber}</option>
                ))}
              </Select>
              {/* <Input placeholder="+1234567890" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" /> */}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleCall} isLoading={loading}>Confirm</Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RightPanel;