// import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AxiosInterceptor from './AxiosInterceptor'; 
import AssistantPage from './pages/AssistantPage'; 
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import DashboardPage from './pages/DashboardPage'; 
import SettingsPage from './pages/SettingsPage';  
import ProtectedRoute from './components/ProtectedRoute';  
import ProfilePage from './pages/ProfilePage'; 
import PhoneNumberPage from './pages/PhoneNumberPage';  
import KnowledgeBasePage from './pages/KnowledgeBasePage';
import PasswordResetPage from './pages/PasswordResetPage';
import BillingPage from './pages/BillingPage';
import AdminCreateUserPage from './pages/AdminCreateUserPage';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import ToolPage from './pages/ToolPage';
import ApiKeysPage from './pages/ApiKeysPage';
import './App.css';

function App() {
    
    return (
        <Router>
            {/* <AxiosInterceptor /> */}
        <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/register" element={<RegisterPage />} /> */}
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<PasswordResetPage />} />
            {/* Wrap protected routes with ProtectedRoute */}
            <Route path="/dashboard"
            element={
                <ProtectedRoute>
                    <DashboardPage />
                </ProtectedRoute>
            }
            />
            <Route path="/assistant" element={ <ProtectedRoute><AssistantPage /></ProtectedRoute> } />
            <Route path="/settings" element={ <ProtectedRoute><SettingsPage /></ProtectedRoute> } />
            <Route path="/api-keys" element={ <ProtectedRoute><ApiKeysPage /></ProtectedRoute> } />
            <Route path="/profile" element={ <ProtectedRoute><ProfilePage /></ProtectedRoute> } />
            <Route path="/phone-numbers" element={ <ProtectedRoute><PhoneNumberPage /></ProtectedRoute> } />
            <Route path="/knowledgebase" element={ <ProtectedRoute><KnowledgeBasePage /></ProtectedRoute> } />
            <Route path="/billing" element={ <ProtectedRoute><BillingPage /></ProtectedRoute> } />
            <Route path="/tool" element={ <ProtectedRoute><ToolPage /></ProtectedRoute> } />
            <Route path="/admin/create-user" element={ <AdminProtectedRoute><AdminCreateUserPage /></AdminProtectedRoute> } />
        </Routes>
      </Router>
    );
}

export default App;
